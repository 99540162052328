@import "../../styles/mixins.scss";

.RoomSize {
  border: rem(1) solid var(--color-dark);
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: rem(1) rem(4);
  border-radius: rem(5);

  .Number {
    @include font-medium;
    font-size: rem(8);
  }
}
