@import "../../styles/mixins.scss";

.Scroll {
  display: flex;
  flex: 1;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.Posts {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  gap: 1.2rem;
  margin: 3rem 0 3rem 0;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;
    justify-content: flex-start;
    margin: rem(48) auto;
    scroll-snap-type: unset;
    overflow-x: unset;
    -webkit-overflow-scrolling: unset;
  }
}

.Post {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 0.8rem 0;
  min-width: calc(100vw - 2rem);

  @include desktop {
    min-width: 22.5rem;
  }

  .Image {
    position: relative;
    width: 48%;
    height: 14.375rem;
    margin-right: 1.25rem;
  }

  .Info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .Title {
    @include font-demi;
    @include font-paragraph;
    margin-bottom: 1rem;
    text-align: left;
  }

  .Byline {
    @include font-book;
    @include font-paragraph;
    text-align: left;
  }
}

.CarouselButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: rem(4);
  margin: rem(30) 0;
}

.CarouselButton {
  width: rem(8);
  height: rem(8);
  background: var(--color-dark);
  opacity: 0.5;
  outline: none;
  border-radius: 50%;

  &.Active {
    opacity: 1;
  }
}
