@import "../../styles/mixins.scss";

.MeetOurClients {
  padding: 0 rem(12);

  @include desktop {
    padding: 0 rem(16);
    margin: rem(120) auto;
  }

  h2 {
    @include font-heading;
    @include font-medium;
    text-align: center;

    @include desktop {
      @include font-heading-medium;
      @include font-heavy;
      text-align: left;
    }
  }

  p {
    @include font-book;
    @include font-paragraph-large;
    margin: rem(33) 0 rem(57) 0;
    text-align: center;

    @include desktop {
      margin: rem(40) 0 rem(64) 0;
      text-align: left;
    }
  }
}

.Scroll {
  display: flex;
  flex: 1;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.Posts {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  gap: 1.2rem;
  margin: 3rem 0 3rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;
    justify-content: flex-start;
    margin: 3rem auto 5rem auto;
    scroll-snap-type: unset;
    overflow-x: unset;
    -webkit-overflow-scrolling: unset;
  }
}

.Post {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 0.8rem 0;
  min-width: calc(100vw - 2rem);
  scroll-snap-align: start;

  @include desktop {
    min-width: 22.5rem;
    scroll-snap-align: unset;
  }

  .Image {
    position: relative;
    width: 48%;
    height: 14.375rem;
    margin-right: 1.25rem;
  }

  .Info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .Title {
    margin-bottom: 1rem;
    @include font-demi;
    @include font-paragraph;
  }

  .Client {
    @include font-book;
    @include font-paragraph;
  }
}

.ViewAll {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include desktop {
    display: none;
  }
}

.LogoList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: rem(10);
  flex-wrap: wrap;

  margin: 4rem 0 8rem 0;

  @include desktop {
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: rem(10);
  }
}

.LogoImage {
  position: relative;

  img {
    max-width: rem(85);
    width: 100%;
    height: 100%;
    @include desktop {
      max-width: rem(140);
    }
  }
}
