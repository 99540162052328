@import "../../styles/mixins.scss";

.SocialMembership {
  background: #fcf9f3;

  .Wrapper {
    padding: 2rem;
    margin: rem(126) auto;
    @include desktop {
      display: flex;
      flex-direction: row;
      padding: 0 rem(16);
    }
  }

  h2 {
    @include font-heading;
    @include font-medium;
    max-width: 19rem;
    margin-top: rem(24);
    text-align: center;

    @include desktop {
      @include font-heading-large;
      margin: rem(32) 0;
      max-width: none;
      text-align: left;
    }
  }

  h3 {
    @include font-normal;
    @include font-medium;
    margin: 0;

    @include desktop {
      @include font-paragraph;
    }
  }

  p {
    @include font-book;
    @include font-paragraph-large;
    text-align: center;
    margin: 0 0 rem(48) 0;

    @include desktop {
      margin: 1rem 0 3rem 0;
      text-align: left;
    }
  }
}

.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
    flex: 1;
    padding: 4rem 0;
    align-items: flex-start;
  }
}

.MobileImage {
  position: relative;
  margin: rem(16) 0;
  width: 100%;
  height: auto;

  @include desktop {
    display: none;
  }
}

.DesktopImage {
  position: relative;
  display: none;
  width: 48%;
  overflow: auto;

  img {
    width: 85%;
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: rem(24) 0;
  }
}
