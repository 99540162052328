@mixin font-light() {
  font-weight: 200;
}
@mixin font-book() {
  font-weight: 300;
}
@mixin font-medium() {
  font-weight: 500;
}
@mixin font-demi() {
  font-weight: 600;
}
@mixin font-bold() {
  font-weight: 700;
}
@mixin font-heavy() {
  font-weight: 800;
}
@mixin font-extra-bold() {
  font-weight: 900;
}

@mixin font-small() {
  font-size: 0.75rem; // 12 px
  line-height: 1rem;
}

@mixin font-mini() {
  font-size: 0.875rem; // 14 px
  line-height: 1.125rem;
}

@mixin font-normal() {
  font-size: rem(16); // 16 px
  line-height: rem(18);
}

@mixin font-button-small {
  font-size: rem(17); // 17px
  line-height: rem(18);
}

@mixin font-paragraph() {
  font-size: rem(18); // 18px
  line-height: rem(20);
  letter-spacing: -0.04125rem;
}

@mixin font-paragraph-large() {
  font-size: rem(20); // 20px
  line-height: rem(24);
}

@mixin font-large() {
  font-size: rem(22); // 22px
}

@mixin font-xlarge() {
  font-size: rem(30); // 30px
}

@mixin font-heading-small() {
  font-size: rem(24); // 24 px
  line-height: rem(28);
}

@mixin font-heading() {
  font-size: rem(32); // 32px
  line-height: rem(36);
}

@mixin font-heading-medium() {
  font-size: rem(42); // 42px
  line-height: rem(46);
}

@mixin font-heading-large() {
  font-size: rem(50); // 50 px
  line-height: rem(54);
}

.font-light {
  @include font-light;
}
.font-book {
  @include font-book;
}
.font-medium {
  @include font-medium;
}
.font-demi {
  @include font-demi;
}
.font-bold {
  @include font-bold;
}
.font-heavy {
  @include font-heavy;
}
.font-extra-bold {
  @include font-extra-bold;
}
.font-small {
  @include font-small;
}
.font-mini {
  @include font-mini;
}
.font-normal {
  @include font-normal;
}
.font-button-small {
  @include font-button-small;
}
.font-paragraph {
  @include font-paragraph;
}
.font-paragraph-large {
  @include font-paragraph-large;
}
.font-large {
  @include font-large;
}
.font-xlarge {
  @include font-xlarge;
}
.font-heading-small {
  @include font-heading-small;
}
.font-heading {
  @include font-heading;
}
.font-heading-medium {
  @include font-heading-medium;
}
.font-heading-large {
  @include font-heading-large;
}
