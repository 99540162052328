@use "sass:math";

$tablet-width: 768px;
$desktop-width: 1024px;

@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
