@import "../../styles/mixins.scss";

.OfferingListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: rem(16) 0;
  padding: 0 0 0 rem(16);
  border: 1px solid var(--color-border);
  background-color: rgba(221, 214, 201, 0.1);
  cursor: pointer;
  width: 100%;

  &.Focused {
    box-shadow: 0px 0px 0.5rem rgb(0 0 0 / 16%);
  }

  @include desktop {
    width: 37.5rem;
    height: rem(140);
    padding: 0 0.5rem 0 1.5rem;
    transition: box-shadow 150ms;
    margin: rem(30) 0;

    &:hover {
      box-shadow: 0px 0px 0.5rem rgb(0 0 0 / 16%);

      .Name {
        text-decoration: underline;
      }
    }

    &.Focused {
      box-shadow: none;
    }
  }

  .Info {
    text-align: left;
    padding: 0.625rem 0;

    @include desktop {
      padding: rem(27) 0;
    }

    .Name {
      display: none;

      @include desktop {
        display: block;
        @include font-bold;
        @include font-heading-small;
      }
    }

    .MobileName {
      display: block;
      @include font-bold;
      font-size: rem(20);

      @include desktop {
        display: none;
      }
    }

    .Description {
      @include font-book;
      @include font-mini;

      @include desktop {
        @include font-demi;
        font-size: rem(17);
        margin: rem(6) 0 rem(16) 0;
      }
    }

    .RoomSize {
      margin-top: rem(10);
    }
  }

  .ImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: rem(10);
  }

  .Image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(84);
    height: rem(84);
    margin-right: rem(5);

    @include desktop {
      width: rem(135);
      height: rem(135);
      margin-right: rem(32);
    }
  }

  .ArrowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1rem;

    @include desktop {
      display: none;
    }
  }
}
