@import "../../styles/mixins.scss";

.CarouselButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: rem(4);
  margin: rem(30) 0;
}

.CarouselButton {
  width: rem(8);
  height: rem(8);
  background: var(--color-dark);
  opacity: 0.5;
  outline: none;
  border-radius: 50%;

  &.Active {
    opacity: 1;
  }
}
